<template>
  <UModal v-model="authStore.openAuthModal">
    <UCard
      :ui="{
        base: 'relative',
        body: {
          padding: 'p-6 sm:px-12 sm:py-8',
        },
      }"
    >
      <div class="absolute top-[15px] right-[15px]" @click="handleCloseAuthModal">
        <UIcon
          class="cursor-pointer ml-auto font-semibold text-xl"
          name="i-heroicons-x-mark"
        />
      </div>
      <LoginForm
        :stay-on-current-page-after-successful-login="true"
        @logged-in-success="handleCloseAuthModal"
      />
      <UAlert
        v-if="!!errorMessage"
        color="pink"
        variant="solid"
        class="mt-3"
        :title="LL[errorMessage.toString()]({ provider, email })"
      />
    </UCard>
  </UModal>
</template>
<script setup lang="ts">
import LoginForm from "./LoginForm.vue";
import { useAuthStore } from "~/features/auth/auth.store";
import { typesafeI18n } from "~/locales/i18n-vue";
const { query } = useRoute();
const { replace } = useRouter();
const authStore = useAuthStore();
const { LL, locale } = typesafeI18n();
const { errorMessage, provider: loginFromProvider, email } = query;
let provider = loginFromProvider;
// NOTE: login with password
if (loginFromProvider === "null") {
  if (locale.value === "vi") {
    provider = "mật khẩu";
  } else {
    provider = "password";
  }
}

watch(
  () => query.isLogin as string,
  (isLogin: string) => {
    if (isLogin === "true") {
      authStore.setOpenAuthModal(true);
      replace({ query: { isLogin: undefined } });
    }
  },
  { immediate: true }
);
const handleCloseAuthModal = () => {
  authStore.setOpenAuthModal(false);
};
</script>
